<template>
  <div class="relative pb-2 mb-2 border-b border-gray-300">
    <div
      class="absolute -inset-0.5 bg-white/50 backdrop-blur-[2px] z-10 transition-opacity duration-300"
      :class="hasAny ? 'opacity-1 pointer-events-auto': 'opacity-0 pointer-events-none'"
    />
    <slot />
  </div>
</template>

<script setup>
  const {hasAny} = useSelection();
</script>
